<template>
      <div class="labelGenCreate">
        <v-breadcrumbs>
          <v-breadcrumbs-item :exact="true" :to="{ name: 'home', params: {} }"
            >Home</v-breadcrumbs-item
          >
          <v-breadcrumbs-divider>/</v-breadcrumbs-divider>
          <v-breadcrumbs-item :exact="true" :to="{ name: 'labelGen', params: {} }">
          Label Gen
          </v-breadcrumbs-item>
          <v-breadcrumbs-divider>/</v-breadcrumbs-divider>
          <v-breadcrumbs-item :to="{ name: 'labelGenCreate', params: {} }">
            Create Label Gen
          </v-breadcrumbs-item>
        </v-breadcrumbs>

        <v-card>
          <v-card-title>Create Label Gen</v-card-title>
          <v-card-text>
            <labelGen-form></labelGen-form>
          </v-card-text>
        </v-card>
      </div>
    </template>
    <script>
    import LabelGenForm from './LabelGenForm.vue';

    export default {
      name: 'labelGen',
      components: {
        LabelGenForm
      },
      created() {
        this.$emit("showParent", false);
      }
    }
    </script>
    